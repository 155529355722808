const logoutIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.97"
        height="24.887"
        viewBox="0 0 24.97 24.887"
    >
        <g id="logout" transform="translate(0 -0.85)">
            <g id="Group_8" data-name="Group 8" transform="translate(0 0.85)">
                <g id="Group_7" data-name="Group 7">
                    <path
                        id="Path_405"
                        data-name="Path 405"
                        d="M12.443,23.663H3.111a1.037,1.037,0,0,1-1.037-1.037V3.961A1.037,1.037,0,0,1,3.111,2.924h9.333a1.037,1.037,0,0,0,0-2.074H3.111A3.115,3.115,0,0,0,0,3.961V22.626a3.115,3.115,0,0,0,3.111,3.111h9.333a1.037,1.037,0,1,0,0-2.074Z"
                        transform="translate(0 -0.85)"
                        fill="#707070"
                    />
                </g>
            </g>
            <g
                id="Group_10"
                data-name="Group 10"
                transform="translate(8.296 6.035)"
            >
                <g id="Group_9" data-name="Group 9">
                    <path
                        id="Path_406"
                        data-name="Path 406"
                        d="M186.465,113.685l-6.3-6.222a1.037,1.037,0,0,0-1.456,1.477l4.506,4.446H171.137a1.037,1.037,0,0,0,0,2.074H183.21l-4.506,4.446a1.037,1.037,0,1,0,1.456,1.477l6.3-6.222a1.036,1.036,0,0,0,0-1.477Z"
                        transform="translate(-170.1 -107.165)"
                        fill="#707070"
                    />
                </g>
            </g>
        </g>
    </svg>
);
const productIcon = (color: string) => (
    <svg
        id="Group_32"
        data-name="Group 32"
        xmlns="http://www.w3.org/2000/svg"
        width="22.749"
        height="24.269"
        viewBox="0 0 22.749 24.269"
    >
        <g id="Group_31" data-name="Group 31" transform="translate(0 0)">
            <path
                id="Path_417"
                data-name="Path 417"
                d="M38.58,5.354,27.561.035a.356.356,0,0,0-.309,0L16.233,5.354a.355.355,0,0,0-.2.32V18.593a.355.355,0,0,0,.2.32l11.019,5.32a.356.356,0,0,0,.309,0l11.019-5.32a.355.355,0,0,0,.2-.32V5.675A.356.356,0,0,0,38.58,5.354ZM27.407.75l10.2,4.925L34.651,7.1a.353.353,0,0,0-.06-.038L24.458,2.173ZM23.656,2.575,33.842,7.493,31.756,8.5,21.574,3.584ZM34.08,8.167V11.89l-1.949.941V9.108Zm3.99,10.2L27.762,23.346V11.217l2.459-1.187a.356.356,0,1,0-.309-.64L27.407,10.6l-.986-.476a.356.356,0,0,0-.309.64l.939.453V23.346L16.743,18.37V6.241l7.849,3.789a.355.355,0,0,0,.309-.64l-7.7-3.716,3.532-1.7L31.416,9.125l0,.006V13.4a.356.356,0,0,0,.51.32l2.66-1.284a.355.355,0,0,0,.2-.32V7.824L38.07,6.241Z"
                transform="translate(-16.032 0.001)"
                fill={color}
            />
        </g>
    </svg>
);
const flyerIcon = (color: string) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.599"
        height="25.562"
        viewBox="0 0 29.599 25.562"
    >
        <g id="flyers" transform="translate(0 -34.917)">
            <g
                id="Group_12"
                data-name="Group 12"
                transform="translate(18.624 42.268)"
            >
                <g id="Group_11" data-name="Group 11">
                    <path
                        id="Path_407"
                        data-name="Path 407"
                        d="M332.375,163.471l-1.49-1.287a.442.442,0,1,0-.578.669l1.489,1.287a1.274,1.274,0,0,1,.131,1.8l-9.674,11.194a.442.442,0,0,0,.669.579l9.674-11.194A2.16,2.16,0,0,0,332.375,163.471Z"
                        transform="translate(-322.145 -162.076)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_14"
                data-name="Group 14"
                transform="translate(15.534 34.917)"
            >
                <g id="Group_13" data-name="Group 13">
                    <path
                        id="Path_408"
                        data-name="Path 408"
                        d="M279.4,41.4l-6.89-5.955a2.157,2.157,0,0,0-3.043.222l-.656.76a.442.442,0,0,0,.669.579l.657-.76a1.273,1.273,0,0,1,1.8-.131l6.89,5.955a.442.442,0,1,0,.579-.669Z"
                        transform="translate(-268.702 -34.917)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_16"
                data-name="Group 16"
                transform="translate(7.2 35.723)"
            >
                <g
                    id="Group_15"
                    data-name="Group 15"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_409"
                        data-name="Path 409"
                        d="M139.376,52.964l-11.92-4a2.16,2.16,0,0,0-2.731,1.359l-.157.469a.442.442,0,0,0,.839.281l.157-.469a1.274,1.274,0,0,1,1.611-.8l11.92,4a1.274,1.274,0,0,1,.8,1.611l-5.546,16.535a.442.442,0,1,0,.839.281L140.736,55.7A2.16,2.16,0,0,0,139.376,52.964Z"
                        transform="translate(-124.545 -48.854)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_18"
                data-name="Group 18"
                transform="translate(0 38.724)"
            >
                <g
                    id="Group_17"
                    data-name="Group 17"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_410"
                        data-name="Path 410"
                        d="M14.73,100.769H2.157A2.16,2.16,0,0,0,0,102.926v17.44a2.16,2.16,0,0,0,2.157,2.157H14.73a2.16,2.16,0,0,0,2.157-2.157v-17.44A2.16,2.16,0,0,0,14.73,100.769ZM16,120.367a1.274,1.274,0,0,1-1.273,1.273H2.157a1.274,1.274,0,0,1-1.273-1.273v-17.44a1.274,1.274,0,0,1,1.273-1.273H14.73A1.274,1.274,0,0,1,16,102.926v17.44Z"
                        transform="translate(0 -100.769)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_20"
                data-name="Group 20"
                transform="translate(1.485 50.933)"
            >
                <g id="Group_19" data-name="Group 19">
                    <path
                        id="Path_411"
                        data-name="Path 411"
                        d="M39.159,311.96H26.126a.442.442,0,0,0,0,.885H39.159a.442.442,0,1,0,0-.885Z"
                        transform="translate(-25.684 -311.96)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_22"
                data-name="Group 22"
                transform="translate(6.978 53.463)"
            >
                <g
                    id="Group_21"
                    data-name="Group 21"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_412"
                        data-name="Path 412"
                        d="M126.62,355.718h-5.478a.442.442,0,1,0,0,.885h5.478a.442.442,0,1,0,0-.885Z"
                        transform="translate(-120.7 -355.718)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_24"
                data-name="Group 24"
                transform="translate(3.547 53.463)"
            >
                <g
                    id="Group_23"
                    data-name="Group 23"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_413"
                        data-name="Path 413"
                        d="M63.753,355.718H61.8a.442.442,0,1,0,0,.885h1.956a.442.442,0,1,0,0-.885Z"
                        transform="translate(-61.354 -355.718)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_26"
                data-name="Group 26"
                transform="translate(3.547 55.154)"
            >
                <g
                    id="Group_25"
                    data-name="Group 25"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_414"
                        data-name="Path 414"
                        d="M70.705,384.969H61.8a.442.442,0,0,0,0,.885h8.909a.442.442,0,0,0,0-.885Z"
                        transform="translate(-61.354 -384.969)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_28"
                data-name="Group 28"
                transform="translate(3.547 56.845)"
            >
                <g
                    id="Group_27"
                    data-name="Group 27"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_415"
                        data-name="Path 415"
                        d="M70.705,414.219H61.8a.442.442,0,1,0,0,.885h8.909a.442.442,0,1,0,0-.885Z"
                        transform="translate(-61.354 -414.219)"
                        fill={color}
                    />
                </g>
            </g>
            <g
                id="Group_30"
                data-name="Group 30"
                transform="translate(3.56 40.324)"
            >
                <g
                    id="Group_29"
                    data-name="Group 29"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_416"
                        data-name="Path 416"
                        d="M71.326,131.981a.442.442,0,0,0-.357-.3l-2.839-.412L66.861,128.7a.442.442,0,0,0-.793,0l-1.27,2.572-2.839.412a.442.442,0,0,0-.245.754l2.054,2-.485,2.827a.442.442,0,0,0,.642.466l2.539-1.335L69,137.731a.442.442,0,0,0,.642-.466l-.485-2.827,2.054-2A.442.442,0,0,0,71.326,131.981Zm-2.95,1.984a.442.442,0,0,0-.127.392l.373,2.173L66.67,135.5a.443.443,0,0,0-.412,0l-1.952,1.026.373-2.173a.442.442,0,0,0-.127-.392l-1.579-1.539,2.182-.317a.442.442,0,0,0,.333-.242l.976-1.977.976,1.977a.442.442,0,0,0,.333.242l2.182.317Z"
                        transform="translate(-61.58 -128.449)"
                        fill={color}
                    />
                </g>
            </g>
        </g>
    </svg>
);
const bannerIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="22"
        viewBox="0 0 24 22"
    >
        <g id="image-gallery" transform="translate(0 -2)">
            <g id="Group_72" data-name="Group 72">
                <path
                    id="Path_434"
                    data-name="Path 434"
                    d="M17.453,24a2,2,0,0,1-.51-.066L1.48,19.793a2.018,2.018,0,0,1-1.414-2.45l1.951-7.272a.5.5,0,0,1,.966.258L1.033,17.6a1.015,1.015,0,0,0,.71,1.229L17.2,22.967a1,1,0,0,0,1.217-.7l.781-2.894a.5.5,0,0,1,.966.26l-.78,2.89A2,2,0,0,1,17.453,24Z"
                    fill="#707070"
                />
            </g>
            <g id="Group_73" data-name="Group 73">
                <path
                    id="Path_435"
                    data-name="Path 435"
                    d="M22,18H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2H22a2,2,0,0,1,2,2V16A2,2,0,0,1,22,18ZM6,3A1,1,0,0,0,5,4V16a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1Z"
                    fill="#707070"
                />
            </g>
            <g id="Group_74" data-name="Group 74">
                <path
                    id="Path_436"
                    data-name="Path 436"
                    d="M9,9a2,2,0,1,1,2-2A2,2,0,0,1,9,9ZM9,6a1,1,0,1,0,1,1A1,1,0,0,0,9,6Z"
                    fill="#707070"
                />
            </g>
            <g id="Group_75" data-name="Group 75">
                <path
                    id="Path_437"
                    data-name="Path 437"
                    d="M4.57,16.93a.5.5,0,0,1-.354-.853l4.723-4.723a1.537,1.537,0,0,1,2.121,0l1.406,1.406,3.892-4.67A1.5,1.5,0,0,1,17.5,7.55h.011a1.5,1.5,0,0,1,1.139.523l5.23,6.1a.5.5,0,0,1-.759.651l-5.23-6.1a.5.5,0,0,0-.38-.174.531.531,0,0,0-.384.18l-4.243,5.091a.5.5,0,0,1-.362.179.481.481,0,0,1-.376-.146l-1.793-1.793a.514.514,0,0,0-.707,0L4.923,16.784a.5.5,0,0,1-.353.146Z"
                    fill="#707070"
                />
            </g>
        </g>
    </svg>
);
const storeIcon = (color: string) => (
    <svg viewBox="0 0 100 100" width="24" height="22">
        <g>
            <path
                fill={color}
                d="M21.6,23.7v13.1c0,3.6,2.8,6.8,6.4,6.9c3.9,0.2,7-2.9,7-6.7V23.1c0-0.1,0-0.2,0-0.3l5.3-16.4   C40.6,5.1,39.6,4,38.3,4h-5.2c-1,0-1.9,0.7-2.1,1.7l-9.2,16.6C21.7,22.7,21.6,23.2,21.6,23.7z"
            ></path>
            <path
                fill={color}
                d="M49.6,43.7c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c3.6-0.2,6.3-3.3,6.3-6.9V23c0-0.1,0-0.1,0-0.2l-3-16.9   c-0.1-1.1-1-2-2.1-2H50h-1.6c-1.1,0-2.1,0.9-2.1,2l-3,16.9c0,0.1,0,0.1,0,0.2v13.8C43.3,40.4,46,43.5,49.6,43.7z"
            ></path>
            <path
                fill={color}
                d="M64.9,22.8c0,0.1,0,0.2,0,0.3V37c0,3.8,3.2,6.9,7,6.7c3.6-0.2,6.4-3.3,6.4-6.9V23.7c0-0.5-0.1-1-0.2-1.5   L69,5.6c-0.2-1-1.1-1.7-2.1-1.7h-5.2c-1.3,0-2.3,1.1-2.1,2.4L64.9,22.8z"
            ></path>
            <path
                fill={color}
                d="M93.3,48c-5.4,0-9.9-3.9-10.8-9.1c-0.9,5-5.1,8.8-10.3,9.1c-0.2,0-0.3,0-0.5,0c-5.4,0-9.9-3.9-10.8-9.1   c-0.9,4.9-5.1,8.7-10.1,9c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.7,0c-5.1-0.3-9.2-4.1-10.1-9c-0.4,2.2-1.4,4.2-3,5.8   c-2.1,2.1-4.8,3.2-7.8,3.2c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c-5.2-0.2-9.4-4-10.3-9c-0.4,2.2-1.4,4.2-3,5.8C12.4,46.9,9.7,48,6.7,48   c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c-0.7,0-1.4-0.1-2-0.3v46.7c0,0.9,0.7,1.7,1.7,1.7h55.9V60.9c0-1.2,1-2.1,2.1-2.1h17.4   c1.2,0,2.1,1,2.1,2.1V96h10.6c0.9,0,1.7-0.7,1.7-1.7V47.7c-0.7,0.2-1.3,0.3-2,0.3C93.6,48,93.4,48,93.3,48z M47.5,76.1   c0,1.2-1,2.1-2.1,2.1H15.1c-1.2,0-2.1-1-2.1-2.1V62.6c0-1.2,1-2.1,2.1-2.1h30.3c1.2,0,2.1,1,2.1,2.1V76.1z"
            ></path>
            <path
                fill={color}
                d="M99.6,21.8L84.1,5.3C83.8,4.5,83,4,82.1,4h-5c-1.4,0-2.5,1.4-2.1,2.7l11.4,15.9c0.1,0.2,0.1,0.4,0.1,0.6V37   c0,3.8,3.2,6.9,7,6.7c3.6-0.2,6.4-3.3,6.4-6.9V24.1C100,23.3,99.8,22.5,99.6,21.8z"
            ></path>
            <path
                fill={color}
                d="M13.5,37V23.2c0-0.2,0-0.4,0.1-0.6L24.9,6.7C25.3,5.3,24.3,4,22.9,4h-5c-0.9,0-1.7,0.5-2,1.4L0.4,21.8   C0.2,22.5,0,23.3,0,24.1v12.7c0,3.6,2.8,6.8,6.4,6.9C10.3,43.9,13.5,40.8,13.5,37z"
            ></path>
        </g>
    </svg>
);
const logoIcon = (color: string) => (
    <svg
        id="Logo"
        xmlns="http://www.w3.org/2000/svg"
        width="150.002"
        height="55.758"
        viewBox="0 0 150.002 55.758"
    >
        <g id="Group_1" data-name="Group 1">
            <path
                id="Path_2"
                data-name="Path 2"
                d="M50.223,12.97A27.755,27.755,0,0,0,39.7,23.251a28.549,28.549,0,0,0,14.272-3.87A27.755,27.755,0,0,0,64.495,9.1,29.507,29.507,0,0,0,50.223,12.97Z"
                transform="translate(-7.89 -9.1)"
                fill="#72bf44"
            />
            <path
                id="Path_4"
                data-name="Path 4"
                d="M139.813,24.356a9.118,9.118,0,0,0-5.685-2.056,9.886,9.886,0,0,0-6.894,3.145A5.16,5.16,0,0,0,123,23.389h-5.2V50.6H123a5.369,5.369,0,0,0,5.322-5.322V31.008a2.163,2.163,0,0,1,3.749-1.451,6.709,6.709,0,0,1,.726.968,4.614,4.614,0,0,0,5.927,1.572A4.525,4.525,0,0,0,139.813,24.356Z"
                transform="translate(8.472 -6.335)"
                fill={color}
            />
            <path
                id="Path_5"
                data-name="Path 5"
                d="M120.462,30.646A13.531,13.531,0,0,0,108.246,22.3c-7.5,0-13.546,6.531-13.546,14.635v.726a16.333,16.333,0,0,0,1.33,5.564,13.532,13.532,0,0,0,12.216,8.346,13,13,0,0,0,9.192-3.87,4.1,4.1,0,0,0-2.9-7.015h-.121a3.934,3.934,0,0,0-3.749,2.54,2.565,2.565,0,0,1-2.3,1.814c-1.33,0-2.419-.968-2.9-4.354l6.048-1.693,5.927-1.572A5.012,5.012,0,0,0,120.462,30.646ZM105.1,34.879c.242-4.717,1.572-6.048,3.145-6.048,1.33,0,2.419.968,2.9,4.354Z"
                transform="translate(3.633 -6.335)"
                fill={color}
            />
            <path
                id="Path_6"
                data-name="Path 6"
                d="M86.707,22.3a9.4,9.4,0,0,0-6.773,3.145A5.27,5.27,0,0,0,75.7,23.268H70.5v.121a21.056,21.056,0,0,1,1.451,1.693A19.327,19.327,0,0,1,75.58,36.814a19.881,19.881,0,0,1-3.628,11.732A21.057,21.057,0,0,1,70.5,50.239V61.972h5.2a5.369,5.369,0,0,0,5.322-5.322V49.272a9.318,9.318,0,0,0,5.806,2.177c6.048,0,11.006-6.531,11.006-14.635C97.714,28.831,92.876,22.3,86.707,22.3ZM84.772,44.313a2.14,2.14,0,0,1-3.749-1.451V30.887a2.163,2.163,0,0,1,3.749-1.451,11.27,11.27,0,0,1,2.54,7.378A11.136,11.136,0,0,1,84.772,44.313Z"
                transform="translate(-1.437 -6.335)"
                fill={color}
            />
            <path
                id="Path_7"
                data-name="Path 7"
                d="M66.107,22.3a9.4,9.4,0,0,0-6.773,3.145A5.27,5.27,0,0,0,55.1,23.268H49.9V62.093h5.2a5.369,5.369,0,0,0,5.322-5.322V49.393a9.318,9.318,0,0,0,5.806,2.177c6.048,0,11.006-6.531,11.006-14.635C77.114,28.831,72.276,22.3,66.107,22.3ZM64.172,44.313a2.129,2.129,0,0,1-1.572.726,2.166,2.166,0,0,1-2.177-2.177V31.008A2.166,2.166,0,0,1,62.6,28.831a2.129,2.129,0,0,1,1.572.726,11.27,11.27,0,0,1,2.54,7.378A11.029,11.029,0,0,1,64.172,44.313Z"
                transform="translate(-5.753 -6.335)"
                fill={color}
            />
            <path
                id="Path_8"
                data-name="Path 8"
                d="M30.938,25.253,23.923,35.292V18.722A5.369,5.369,0,0,0,18.6,13.4H13.4V52.225h5.2A5.369,5.369,0,0,0,23.923,46.9V40.372l6.41,11.974H41.46L32.51,36.381l7.5-11.369H30.938Z"
                transform="translate(-13.4 -8.199)"
                fill={color}
            />
            <path
                id="Path_9"
                data-name="Path 9"
                d="M43.3,23.2H38.1V50.414h5.2a5.369,5.369,0,0,0,5.322-5.322V28.4A5.266,5.266,0,0,0,43.3,23.2Z"
                transform="translate(-8.225 -6.146)"
                fill={color}
            />
            <path
                id="Path_3"
                data-name="Path 3"
                d="M38.2,16.215a14.02,14.02,0,0,0,1.814,7.015,12.879,12.879,0,0,0,1.814-7.015A14.02,14.02,0,0,0,40.014,9.2,12.6,12.6,0,0,0,38.2,16.215Z"
                transform="translate(-8.204 -9.079)"
                fill="#72bf44"
            />
            <path
                id="Path_3-2"
                data-name="Path 3"
                d="M38.2,16.215a14.02,14.02,0,0,0,1.814,7.015,12.879,12.879,0,0,0,1.814-7.015A14.02,14.02,0,0,0,40.014,9.2,12.6,12.6,0,0,0,38.2,16.215Z"
                transform="translate(-8.204 -9.079)"
                fill="#72bf44"
            />
        </g>
    </svg>
);
const brandIcon = (color: string) => (
    <svg
        enable-background="new 0 0 50 50"
        version="1.1"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
        id="Group_35"
        data-name="Group 32"
        width="22.749"
        height="24.269"
    >
        <rect fill="none" height="50" width="50" />
        <circle
            cx="39"
            cy="11"
            fill="none"
            r="3"
            stroke={color}
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="2"
        />
        <path
            d="M47,5.5  C47,4.119,45.881,3,44.5,3c-0.156,0-14.876,0.002-14.876,0.002c-1.33,0-2.603-0.07-3.341,0.668L3.554,26.398  c-0.739,0.738-0.739,1.936,0,2.674l17.374,17.374c0.738,0.738,1.936,0.738,2.674,0L46.33,23.717c0.738-0.737,0.668-1.98,0.668-3.34  C46.998,20.377,47,5.656,47,5.5z"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="2"
        />
    </svg>
);
export {
    logoutIcon,
    productIcon,
    flyerIcon,
    bannerIcon,
    storeIcon,
    logoIcon,
    brandIcon
};
