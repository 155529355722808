// Navbar.tsx
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from 'Context/AuthProvider';
import Logo from 'components/Login/Logo';
import {
    logoutIcon,
    productIcon,
    flyerIcon,
    storeIcon,
    brandIcon
} from 'components/assets/images';
import './styles.css';

interface NavbarLinkProps {
    to: string;
    children: React.ReactNode;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ to, children }) => {
    const { pathname } = useLocation();
    const isActive = pathname === to;

    return (
        <RouterLink
            reloadDocument
            to={to}
            className={isActive ? 'active-link' : ''}
        >
            {children}
        </RouterLink>
    );
};
const Navbar: React.FC = () => {
    const { isLoggedIn, logout } = useAuth();
    const { pathname } = useLocation();
    const handleLogout = () => {
        logout();
    };

    return (
        <div className="navbar p-3">
            <div className="top-section">
                <Logo color="#ed1c24" />
            </div>
            <div className="middle-section">
                {/* Navbar items */}
                <ul className="flex flex-col gap-1 f17">
                    <li>
                        <NavbarLink to="/brands">
                            <div className="flex gap-2">
                                <div className="w-[40px]">
                                    {brandIcon(
                                        pathname === '/brands'
                                            ? '#ED1C24'
                                            : '#707070'
                                    )}
                                </div>
                                <div>Brands</div>
                            </div>
                        </NavbarLink>
                    </li>
                    <li>
                        <NavbarLink to="/product">
                            <div className="flex gap-2">
                                <div className="w-[40px]">
                                    {productIcon(
                                        pathname === '/product'
                                            ? '#ED1C24'
                                            : '#707070'
                                    )}
                                </div>
                                <div>Product</div>
                            </div>
                        </NavbarLink>
                    </li>
                    <li>
                        <NavbarLink to="/flyer">
                            <div className="flex gap-2">
                                <div className="w-[40px]">
                                    {flyerIcon(
                                        pathname === '/flyer'
                                            ? '#ED1C24'
                                            : '#707070'
                                    )}
                                </div>
                                <div>Flyer</div>
                            </div>
                        </NavbarLink>
                    </li>
                    <li>
                        <NavbarLink to="/poster">
                            <div className="flex gap-2">
                                <div className="w-[40px]">
                                    {flyerIcon(
                                        pathname === '/poster'
                                            ? '#ED1C24'
                                            : '#707070'
                                    )}
                                </div>
                                <div>Poster</div>
                            </div>
                        </NavbarLink>
                    </li>
                    <li>
                        <NavbarLink to="/stores">
                            <div className="flex gap-2">
                                <div className="w-[40px]">
                                    {storeIcon(
                                        pathname === '/stores'
                                            ? '#ED1C24'
                                            : '#707070'
                                    )}
                                </div>
                                <div>Stores</div>
                            </div>
                        </NavbarLink>
                    </li>
                </ul>
            </div>
            <div className="bottom-section">
                <div className="logout-icon flex gap-2" onClick={handleLogout}>
                    {logoutIcon()} Logout
                </div>
            </div>
        </div>
    );
};

export default Navbar;
