import React, { useState, useEffect } from 'react';
import Subheader from '../Subheader';
// import Input from "../../Inputs/Input";
import { _createBrand, _updateBrands, _getBrandById } from 'routes/routes';
import { CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';
import FileUpload from 'components/Inputs/FileUpload';
import Header from '../Header';
import useBeforeUnload from 'hooks/useBeforeUnload';
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;
interface ManageProps {
    onClose: () => void;
    onSave: () => void;
    type: string;
    updateId: number | null;
}
const Manage: React.FC<ManageProps> = ({ onClose, type, onSave, updateId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const setError = useError();
    const [file, setFile] = useState<File | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});
    const [imageName, setImageName] = useState<string>('');
    const [formchanged, setFormChanged] = useState<boolean>(false);

    useBeforeUnload(formchanged);
    useEffect(() => {
        if (updateId) {
            const fetchOptions = async () => {
                try {
                    const response = await _getBrandById(updateId);
                    setLoaded(true);
                    fillinputs(response.data);
                } catch (err) {
                    setError(err);
                }
            };
            fetchOptions();
        } else {
            setLoaded(true);
        }
    }, []);

    const handleClose = () => {
        setFile(null);
        setLanguageInputs({});
        setImageName('');
        onClose();
    };
    const fillinputs = (data: {}[]) => {
        let tmpdata: any = {};
        data.forEach((brand: any) => {
            tmpdata.img_path = brand.img_path;
            tmpdata['brand-name'] = brand.name;
        });

        setImageName(tmpdata.img_path);
        setLanguageInputs(tmpdata);
    };
    const createParams = () => {
        const params = {
            img_path: imageName,
            name: languageInputs['brand-name']
        };
        console.log(params);
        return params;
    };
    const checkParams = (params: any) => {
        if (params.name === '' || params.name == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`brand-name`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`brand-name`]: false
            }));
        }
        return {
            validation: true,
            msg: ''
        };
    };
    const handleSubmit = async () => {
        const params = createParams();
        const validation = checkParams(params);
        if (type == 'Edit') {
            if (validation.validation) {
                try {
                    await _updateBrands(params, updateId);
                    enqueueSnackbar('Product Updated ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        } else {
            if (validation.validation) {
                try {
                    await _createBrand(params);
                    enqueueSnackbar('Product Created ', { variant: 'success' });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        }

        onSave(); // Call the onClose callback to close the component
    };
    const handleInput2Change = (
        value: string,
        type: string,
        datatype: string
    ) => {
        let trimmedValue = value.trimStart();
        setFormChanged(true);
        if (datatype === 'number') {
            trimmedValue = trimmedValue.replace(/[^\d.]/g, '');
            trimmedValue = trimmedValue.replace(/(\..*?)\..*/g, '$1');
        }
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const handleFileUpload = (uploadedFile: File, image_name: string) => {
        setFormChanged(true);
        setFile(uploadedFile);
        setImageName(image_name);
    };

    return (
        <div className="h-full">
            <Header
                type={type + ' Product'}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            />
            <Subheader />
            {loaded ? (
                <div
                    className="flex manage-body pb-[50px]"
                    style={{ height: 'calc(100% - 200px);' }}
                >
                    <div className="body-content flex flex-col px-[50px]">
                        <div className="flex flex-col h-full">
                            <div className="header-text py-8 text-[#969696] font-bold text-lg ">
                                <span>Brand Information</span>
                            </div>
                            <div className="product flex flex-initial flex-col pr-[70px] gap-4">
                                <FileUpload
                                    key={1}
                                    onFileUpload={handleFileUpload}
                                    img_name={imageName}
                                    // label={`Image 1`}
                                />
                                <div className="product-name product flex flex-col gap-2">
                                    <label className="">Brand name</label>
                                    <CustomTextField
                                        type="text"
                                        required
                                        error={
                                            languageInputsError[`brand-name`]
                                        }
                                        placeholder="Brand name"
                                        value={
                                            languageInputs[`brand-name`] || ''
                                        }
                                        name="brand-name"
                                        autoComplete="brand-name"
                                        onChange={(e) =>
                                            handleInput2Change(
                                                e.target.value,
                                                'brand-name',
                                                'text'
                                            )
                                        }
                                        style={{ background: '#F9FDF7' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex manage-body f24 font-bold text-[#707070] justify-center items-center">
                    <CircularProgress></CircularProgress>
                </div>
            )}
        </div>
    );
};

export default Manage;
