import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import _config from 'config';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
let formattedColumns: GridColDef[] = [];
let formattedRows: GridRowsProp = [];
let selectedColumns: GridColDef[] = [];
let selectedRows: GridRowsProp = [];
function sortArrayOfObjectsByProperty(
    arr: any,
    property: string,
    type?: string
) {
    if (type == 'asc') {
        return arr.sort((a: any, b: any) => {
            if (a[property] > b[property]) return 1;
            if (a[property] < b[property]) return -1;
            return 0;
        });
    }
    return arr.sort((a: any, b: any) => {
        if (a[property] < b[property]) return 1;
        if (a[property] > b[property]) return -1;
        return 0;
    });
}

export const formatProductTable = (data: any) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        {
            field: 'image',
            headerName: 'Thumbnail',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: 'external_id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'campaign', headerName: 'Campaign', flex: 1 },
        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: () => (
                <DeleteIcon style={{ color: '#ED1B25', cursor: 'pointer' }} />
            )
        },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: () => (
                <EditIcon style={{ color: '#72BF45', cursor: 'pointer' }} />
            )
        }
    ];

    // Format rows
    data = sortArrayOfObjectsByProperty(data, 'id');
    data.forEach((item: any) => {
        tmp_rows.push({
            id: item.id,
            imageUrl: `${_config.s3_url}/${item.img_path}`,
            external_id: item.external_id,
            name: item.name,
            campaign: 'N/A',
            productId: item.id
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};
// Status;
// 0 - pending;
// 1 - started;
// 2 - active;
const format_status = (status: number) => {
    switch (status) {
        case 0:
            return 'PENDING';
        case 1:
            return 'POSTED';
        case 2:
            return 'ACTIVE';
    }
};
export const formatFlyerTable = (data: any) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        {
            field: 'image',
            headerName: 'Thumbnail',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: 'name', headerName: 'ID', flex: 1 },
        { field: 'desc', headerName: 'Name', flex: 1 },
        { field: 'active_from', headerName: 'From', flex: 1 },
        { field: 'active_to', headerName: 'To', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <div className={`status-${params.row.status}`}>
                    {format_status(params.row.status)}
                    {/* {params.row.status} */}
                </div>
            )
        },

        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: () => (
                <DeleteIcon style={{ color: '#ED1B25', cursor: 'pointer' }} />
            )
        },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: () => (
                <EditIcon style={{ color: '#72BF45', cursor: 'pointer' }} />
            )
        }
    ];

    // Format rows
    data = sortArrayOfObjectsByProperty(data, 'id');
    data.forEach((item: any) => {
        tmp_rows.push({
            id: item.id,
            imageUrl: `${_config.s3_url}/${item.img_path}`,
            name: item.name,
            desc:
                item.post_languages[0].language_id === 1
                    ? item.post_languages[0].title
                    : item.post_languages[1].title,
            campaign: 'N/A',
            active_from: moment.utc(item.from_date).format('DD-MM-YYYY'),
            active_to: item.to_date
                ? moment.utc(item.to_date).format('DD-MM-YYYY')
                : 'N/A',
            productId: item.id,
            status: item.status
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};
export const formatProductTableSelect = (data: any, edit?: boolean) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        { field: '_id', headerName: 'ID', flex: 1 },
        {
            field: 'image',
            headerName: 'Thumbnail',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'price', headerName: 'Price', flex: 1 }
    ];

    // Format rows
    data.forEach((item: any) => {
        tmp_rows.push({
            imageUrl: `${_config.s3_url}/${item.img_path}`,
            id: item.id,
            _id: item.external_id,
            name: item.name,
            productId: item.id,
            price: item.product_prices[0].price
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};
export const formatSelectedProductTable = (
    data: any,
    edit?: boolean,
    options?: any
) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    selectedColumns = [
        {
            field: 'image',
            headerName: 'Image',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: '_id', headerName: 'Product ID', flex: 1 },
        { field: 'name', headerName: 'Product Name', flex: 1 },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            type: 'number',
            headerAlign: 'left',
            editable: edit, // Make price field editable based on the 'edit' parameter
            renderCell: (params: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>{params.value}</div>
                    {edit && ( // Show edit icon only if 'edit' is true
                        <EditIcon
                            // onClick={() => {
                            //   // if (apiRef.current) {
                            //   //   apiRef.current.setEditCell(params.id, 'price');
                            //   // }
                            // }}
                            style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                    )}
                </div>
            )
        }
    ];

    // Format rows
    data = sortArrayOfObjectsByProperty(data, 'sort_index', 'asc');
    data.forEach((item: any, index: number) => {
        tmp_rows.push({
            imageUrl: `${_config.s3_url}/${item.img_path}`,
            id: item.id,
            _id: item.external_id,
            name: item.name,
            productId: item.id,
            price: item.product_prices[0].price,
            new_price:
                item.product_prices[0].new_price ||
                item.product_prices[0].price,
            sort_index: item.sort_index,
            product_detail_category_id: item.product_detail_category_id
                ? item.product_detail_category_id
                : '-1'
        });
    });
    selectedRows = tmp_rows;
    return { selectedColumns, selectedRows };
};

export const formatLocationTable = (data: any) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        // { field: 'id', headerName: 'Product ID', flex: 1 },
        { field: 'name', headerName: 'Store Name', flex: 1 },
        { field: 'address', headerName: 'Address', flex: 2 },
        { field: 'opening_at', headerName: 'Opens', flex: 1 },
        { field: 'closing_at', headerName: 'Closes', flex: 1 },
        { field: 'country', headerName: 'Country', flex: 1 },
        { field: 'city', headerName: 'City', flex: 1 },
        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: () => (
                <DeleteIcon style={{ color: '#ED1B25', cursor: 'pointer' }} />
            )
        },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: () => (
                <EditIcon style={{ color: '#72BF45', cursor: 'pointer' }} />
            )
        }
    ];

    // Format rows
    data.forEach((item: any) => {
        tmp_rows.push({
            id: item.id,
            name: item.name,
            address: item.address,
            opening_at: moment(item.opening_at, [
                moment.ISO_8601,
                'HH:mm'
            ]).format('HH:mm'),
            closing_at: moment(item.closing_at, [
                moment.ISO_8601,
                'HH:mm'
            ]).format('HH:mm'),
            country: item.country.short_name,
            city: item.city.name,
            productId: item.id
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};

export const formatPosterTable = (data: any) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        {
            field: 'image',
            headerName: 'Thumbnail',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: () => (
                <DeleteIcon style={{ color: '#ED1B25', cursor: 'pointer' }} />
            )
        },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: () => (
                <EditIcon style={{ color: '#72BF45', cursor: 'pointer' }} />
            )
        }
    ];

    // Format rows
    data = sortArrayOfObjectsByProperty(data, 'list_sort', 'asc');
    data.forEach((item: any) => {
        tmp_rows.push({
            id: item.id,
            imageUrl: `${_config.s3_url}/${item.poster_items[0].img_path}`,
            name: item.name,
            productId: item.id
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};

export const formatBrandsTable = (data: any) => {
    // Define column definitions
    let tmp_rows: any[] = [];
    formattedColumns = [
        {
            field: 'image',
            headerName: 'Thumbnail',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="image-container"
                    style={{ backgroundImage: params.row.imageUrl }}
                >
                    <img
                        src={params.row.imageUrl}
                        alt="Product"
                        style={{ width: 'auto', height: '100%' }}
                    />
                </div>
            )
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'delete',
            headerName: '',
            width: 50,
            renderCell: () => (
                <DeleteIcon style={{ color: '#ED1B25', cursor: 'pointer' }} />
            )
        },
        {
            field: 'edit',
            headerName: '',
            width: 50,
            renderCell: () => (
                <EditIcon style={{ color: '#72BF45', cursor: 'pointer' }} />
            )
        }
    ];

    // Format rows
    data = sortArrayOfObjectsByProperty(data, 'id');
    data.forEach((item: any) => {
        tmp_rows.push({
            id: item.id,
            productId: item.id,
            imageUrl: `${_config.s3_url}/${item.img_path}`,
            name: item.name
        });
    });
    formattedRows = tmp_rows;
    return { formattedColumns, formattedRows };
};
