import React, { useState, useCallback, useEffect } from 'react';
import Subheader from '../Subheader';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import {
    _getCountries,
    Country,
    _getCityByCountryId,
    _createLocation,
    _getLocationById,
    _updateLocation
} from 'routes/routes';
import { useSnackbar } from 'notistack';
import _config from 'config';
import moment, { Moment } from 'moment';
import useError from 'hooks/useError';
import GoogleMapReact from 'google-map-react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Header from '../Header';
import FileUpload from 'components/Inputs/FileUpload';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import useBeforeUnload from 'hooks/useBeforeUnload';
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;
interface ManageProps {
    onClose: () => void;
    onSave: () => void;
    type: String;
    updateId: number | null;
}
const Manage: React.FC<ManageProps> = ({ onClose, type, onSave, updateId }) => {
    const [startTime, setStartTime] = React.useState<Moment | null>(
        moment('2022-04-17T07:00')
    );
    const [closeTime, setCloseTime] = React.useState<Moment | null>(
        moment('2022-04-17T21:00')
    );
    const [mapLoaded, setMapLoaded] = useState<Boolean>(false);
    const [selectedPlace, setSelectedPlace] = useState<any>(null);
    const [_map, setMap] = useState<google.maps.Map | null>(null);
    const [_maps, setMaps] = useState(null);
    const [loaded, setLoaded] = useState<Boolean>(
        type == 'edit' ? false : true
    );
    const setError = useError();
    const { enqueueSnackbar } = useSnackbar();
    const [file, setFile] = useState<File | null>(null);
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});
    const [imageName, setImageName] = useState<string>('');
    const [countries, setCountries] = useState<Country[]>([]);
    const [cities, setCities] = useState<any>([]);
    const [selectedCity, setSelectedCity] = useState<any>();
    const [number, setNumber] = useState<string | undefined>();
    const [formchanged, setFormChanged] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');
    useBeforeUnload(formchanged);
    useEffect(() => {
        if (mapLoaded) {
            const fetchOptions = async () => {
                try {
                    const countries = await _getCountries();
                    setCountries(countries.data);
                    if (updateId) {
                        const response = await _getLocationById(updateId);
                        fillinputs(response.data);
                    }
                } catch (err) {
                    setError(err);
                }
            };
            fetchOptions();
        }
    }, [mapLoaded]);

    const handleClose = () => {
        onClose();
    };
    const fillinputs = async (data: {}[]) => {
        let tmpdata: any = {};
        data.forEach((location: any) => {
            tmpdata['NAME'] = location.name;
            tmpdata['img_path'] = location.img_path;
            tmpdata['country_id'] = location.country_id;
            tmpdata['city_id'] = location.city_id;
            tmpdata['number'] = location.phone_number;
            tmpdata['center'] = { lat: location.lat, lng: location.lng };
            tmpdata['Address'] = location.address;
            tmpdata['startTime'] = location.opening_at;
            tmpdata['closeTime'] = location.closing_at;
        });
        setselectedCountry(tmpdata.country_id);
        const cities = await _getCityByCountryId(tmpdata.country_id);
        setCities(cities.data);
        setSelectedCity(tmpdata.city_id);
        setImageName(tmpdata.img_path);
        setLanguageInputs(tmpdata);
        setNumber(tmpdata.number);
        setCenter(tmpdata.center);
        createMarker(tmpdata.center.lat, tmpdata.center.lng);
        setAddress(tmpdata.Address);
        setStartTime(moment(`2022-04-17T${tmpdata.startTime}`));
        setCloseTime(moment(`2022-04-17T${tmpdata.closeTime}`));
        setLoaded(true);
    };
    const createParams = () => {
        interface Params {
            img_path: string;
            name: string;
            opening_at: string;
            closing_at: string;
            lat: number;
            lng: number;
            address: string;
            phone_number: string | undefined;
            country_id?: number; // Optional property for conditional assignment
            city_id?: number; // Optional property for conditional assignment
        }
        let params: Params = {
            img_path: imageName,
            name: languageInputs['NAME'],
            opening_at: moment(startTime).format('HH:mm'),
            closing_at: moment(closeTime).format('HH:mm'),
            // country_id: parseInt(selectedCountry),
            lat: center.lat,
            lng: center.lng,
            // city_id: selectedCity,
            address: address,
            phone_number: number
        };
        if (type != 'Edit') {
            params['country_id'] = parseInt(selectedCountry);
            params['city_id'] = parseInt(selectedCountry);
        }
        return params;
    };
    const checkParams = (params: any) => {
        // const params = createParams();
        if (params.name === '' || params.name == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`NAME`]: false
            }));
        }
        if (params.address === '' || params.address == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`address`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`address`]: false
            }));
        }
        if (params.phone_number === '' || params.phone_number == undefined) {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`number`]: true
            }));
        } else {
            setLanguageInputsError((prevState) => ({
                ...prevState,
                [`number`]: false
            }));
        }
        if (params.name === '' || params.name == undefined) {
            return {
                validation: false,
                msg: 'Name input is Required'
            };
        }
        if (params.address === '' || params.address == undefined) {
            return {
                validation: false,
                msg: 'Name input is Required'
            };
        }
        if (
            params.opening_at === '' ||
            params.opening_at == undefined ||
            params.opening_at == 'Invalid date'
        ) {
            return {
                validation: false,
                msg: 'Opens At field is Required'
            };
        }
        if (
            params.closing_at === '' ||
            params.closing_at == undefined ||
            params.closing_at == 'Invalid date'
        ) {
            return {
                validation: false,
                msg: 'Closes At field is Required'
            };
        }
        if (
            type != 'Edit' &&
            (params.country_id === '' || params.country_id == undefined)
        ) {
            return {
                validation: false,
                msg: 'Please select a country'
            };
        }
        if (
            type != 'Edit' &&
            (params.city_id === '' || params.city_id == undefined)
        ) {
            return {
                validation: false,
                msg: 'Please select a City'
            };
        }
        return {
            validation: true,
            msg: ''
        };
    };
    const handleSubmit = async () => {
        const params = createParams();
        const validation = checkParams(params);
        if (type == 'Edit') {
            if (validation.validation) {
                try {
                    await _updateLocation(params, updateId);
                    enqueueSnackbar('Location Updated ', {
                        variant: 'success'
                    });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        } else {
            if (validation.validation) {
                try {
                    await _createLocation(params);
                    enqueueSnackbar('Location Created ', {
                        variant: 'success'
                    });
                    handleClose();
                } catch (err) {
                    setError(err);
                }
            } else {
                enqueueSnackbar(validation?.msg, { variant: 'error' });
            }
        }

        onSave(); // Call the onClose callback to close the component
    };

    const handleInput2Change = (value: string, type: string) => {
        const trimmedValue = value.trimStart();
        setFormChanged(true);
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const [selectedCountry, setselectedCountry] = useState<string>('');
    const [selectedCountryObj, setselectedCountryObj] = useState<any>([]);
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (loaded) {
            setFormChanged(true);
            const selectedobj = countries.filter(
                (item) => event.target.value == item.id.toString()
            );
            setselectedCountryObj(selectedobj);

            setselectedCountry(event.target.value);
            const cities = await _getCityByCountryId(selectedobj[0].id);
            setCities(cities.data);
        }
    };
    useEffect(() => {
        if (loaded) {
            const selectedobj = countries.filter(
                (item) => selectedCountry == item.id.toString()
            );
            setselectedCountryObj(selectedobj);
        }
    }, [selectedCountry]);
    const selectoptions = (
        <select
            id="options"
            className="select-countries custom-input"
            disabled={type != 'Edit' ? false : true}
            value={selectedCountry}
            onChange={handleSelectChange}
        >
            <option key={-1}>Select Country</option>
            {countries.map((country) => (
                <option key={country.id} value={country.id}>
                    {country.name}
                </option>
            ))}
        </select>
    );
    const handleSelectedCitiesChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (loaded) {
            setFormChanged(true);
            setSelectedCity(event.target.value);
        }
    };
    const multiSelectComponent = cities.length ? (
        <select
            id="options"
            className="select-countries custom-input"
            disabled={type != 'Edit' ? false : true}
            value={selectedCity}
            onChange={handleSelectedCitiesChange}
        >
            <option key={-1}>Select City</option>
            {cities.map((city: any) => (
                <option key={city.id} value={city.id}>
                    {city.name}
                </option>
            ))}
        </select>
    ) : (
        ''
    );
    const onMapLoaded = useCallback((map: google.maps.Map, maps: any) => {
        if (!_map) {
            setMaps(maps);
            setMap(map);
        }

        const input = document.getElementById(
            'addressAutocomplete'
        ) as HTMLInputElement;
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            setSelectedPlace(place);
        });
        setMapLoaded(true);
    }, []);
    const [marker, setMarker] = useState<any>(null);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [address, setAddress] = useState('');
    const [urlCords, setUrlCords] = useState<{
        lat: number;
        lng: number;
    } | null>(null);
    const handleAddressInputChange = (value: string) => {
        setFormChanged(true);
        const trimmedValue = value.trimStart();
        setAddress(trimmedValue);
    };
    useEffect(() => {
        if (_maps && _map) {
            setAddress(selectedPlace?.formatted_address || '');
            if (marker) {
                marker.setMap(null);
            }
            if (selectedPlace) {
                const lat = selectedPlace.geometry?.location.lat() || 0;
                const lng = selectedPlace.geometry?.location.lng() || 0;
                createMarker(lat, lng);
            }
        }
    }, [selectedPlace]);
    useEffect(() => {
        if (url) {
            const extractedCoords = extractLatLng(url);
            if (extractedCoords) {
                setUrlCords(extractedCoords);

                if (_maps && _map) {
                    if (marker) {
                        marker.setMap(null);
                    }
                    const lat = extractedCoords?.lat || 0;
                    const lng = extractedCoords?.lng || 0;
                    createMarker(lat, lng);
                }
            }
        }
    }, [url]);
    const extractLatLng = (url: string) => {
        const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
        const match = url.match(regex);
        if (match) {
            return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
        }
        return null;
    };
    const createMarker = (lat: number, lng: number) => {
        if (_maps && _map) {
            const location = new window.google.maps.LatLng(lat, lng);
            const mrk = new window.google.maps.Marker({
                position: location,
                draggable: true,
                map: _map
            });
            setCenter({ lat: lat, lng: lng });
            mrk.addListener('dragend', (e: any) => {
                let mrkpos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                setCenter(mrkpos);
            });
            setMarker(mrk);
            mrk.setMap(_map);
            // maps.Marker()
            _map.setCenter(location);
            _map.setZoom(15);
        }
    };
    const handleFileUpload = (uploadedFile: File, image_name: string) => {
        setFormChanged(true);
        setFile(uploadedFile);
        setImageName(image_name);
    };

    return (
        <div className="h-full">
            <Header
                type={type + ' Store'}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            />
            <Subheader
                multiSelectComponent={selectoptions}
                secondMultiSelect={multiSelectComponent}
                description="Select Country and City"
            />
            <div
                className="flex manage-body pb-[50px] hide-scrollbar"
                style={{ height: 'calc(100% - 200px);' }}
            >
                <div className="body-content flex flex-col pl-[50px] w-full">
                    <div className="header-text py-8 text-[#969696] font-bold text-lg">
                        <span>Store Information</span>
                    </div>
                    <div className="flex h-full m-2">
                        <div className="manage-first flex flex-initial flex-col pr-[70px] gap-4">
                            <FileUpload
                                onFileUpload={handleFileUpload}
                                img_name={imageName}
                            />

                            <div className="product-name product flex flex-col gap-2">
                                <label className="">Store Name</label>
                                <CustomTextField
                                    type="text"
                                    required
                                    placeholder="Name"
                                    error={languageInputsError[`NAME`]}
                                    value={languageInputs[`NAME`] || ''}
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            'NAME'
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                />
                            </div>
                            <div className=" product-name product flex flex-col gap-2">
                                <label className="">Store Address</label>
                                <CustomTextField
                                    type="text"
                                    id="addressAutocomplete"
                                    required
                                    error={languageInputsError[`address`]}
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) =>
                                        handleAddressInputChange(e.target.value)
                                    }
                                    style={{ background: '#F9FDF7' }}
                                />
                            </div>
                            <div className="flex flex-col">
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DemoContainer
                                        components={[
                                            'TimePicker',
                                            'TimePicker'
                                        ]}
                                    >
                                        <TimePicker
                                            label="Opens At"
                                            // defaultValue = {moment()}
                                            value={startTime}
                                            onChange={(newValue) =>
                                                setStartTime(newValue)
                                            }
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock
                                            }}
                                            slotProps={{
                                                textField: { size: 'small' }
                                            }}
                                        />
                                        <TimePicker
                                            label="Closes At"
                                            value={closeTime}
                                            onChange={(newValue) =>
                                                setCloseTime(newValue)
                                            }
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock
                                            }}
                                            slotProps={{
                                                textField: { size: 'small' }
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className="product-name product flex flex-col gap-2">
                                <label className="">Phone Number</label>
                                <PhoneInput
                                    className={` ${
                                        languageInputsError[`number`]
                                            ? 'validation-error'
                                            : ''
                                    }  phone`}
                                    placeholder="Enter phone number"
                                    error={languageInputsError[`number`]}
                                    value={number}
                                    onChange={setNumber}
                                    countries={['MK', 'XK']}
                                />
                                <label className="">
                                    Google Maps Url helper
                                </label>
                                <CustomTextField
                                    type="text"
                                    required
                                    placeholder="Url"
                                    value={url || ''}
                                    onChange={(e) => setUrl(e.target.value)}
                                    style={{ background: '#F9FDF7' }}
                                />
                            </div>
                        </div>
                        <div className="map flex-1">
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: _config.google_map_key,
                                    libraries: ['places']
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                defaultCenter={center}
                                defaultZoom={1}
                                onGoogleApiLoaded={({ map, maps }) =>
                                    onMapLoaded(map, maps)
                                }
                            >
                                {/* {center.lat !== 0 || center.lng !== 0 ? (
                                    <Marker
                                        lat={59.955413}
                                        lng={30.337844}
                                        text="My Marker"
                                    />
                                ) : (
                                    ''
                                )} */}
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Manage;
